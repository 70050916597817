<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Router management platform of<br>Htroy Network Research Cloud ☁️</p>
                  <CInput
                    placeholder="Email"
                    autocomplete="email"
                    :value.sync="email"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    :value.sync="password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" @click="login">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="d-lg-none" @click="$router.push('/auth/register')">Register now!
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12">
                      <hr>
                      <p>
                        <CLink href="https://htroy.dev">Htroy</CLink>
                        © 2022 All Rights Reserved.
                      </p>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                  @click="$router.push('/auth/register')"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      title="Login"
      color="primary"
      :show.sync="loginMsgModal"
    >
      {{ loginMsg }}
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      loginMsg: "Please wait..",
      loginMsgModal: false
    };
  },
  methods: {
    login: function() {
      this.loginMsgModal = true;
      this.$axios.post("auth/login", {
        email: this.email,
        password: this.password
      }, {
        maxRedirects: 0
      })
        .then((response) => {
          this.loginMsg = response.data.msg;
          if (response.data.code === 200) {
            setTimeout(() => this.$router.push({ path: "/index" }), 3000);
          }
        });
    }
  }
};
</script>
